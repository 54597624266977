import React, {useEffect, useState} from 'react';
import TenantList from '../components/TenantList';
import ReactToolTip from 'react-tooltip';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { ZuiIconPlus, ZuiButton} from "@zeiss/zui-react"
import { TenantAPI, CreateOrganizationAPI, DeleteOrganizationAPI, GetAndUpdateOrganizationAPI, GetOrganizationLicenceDetails } from '../api/TenantAPI';
import '../styles/Tenants.css';
import { refreshAccessToken } from "../../utils/APIUtils";
import PlainTextData from '../../common/components/PlainTextData';
import { SortingHeader } from '../../common/components/SortingHeader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { config } from '../../utils/Config';
import '../../common/styles/SelectCustom.css';

export const Tenants = () => {
    const PAGE_NUMBER = 1;
    const [show, setShow] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [error, setError] = useState('');
    const [isFormError, setIsFormError] = useState('');
    const [mode, setMode] = useState('Create');
    const [selectedOrganization, setSelectedOrganization] = useState({
        organizationName: '',
        description: '',
        contactFirstName: '',
        contactLastName: '',
        emailAddress: ''
    });
    const [organizationsData, setTenantsData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [licenceData, setLicenceData] =  useState([]);
    const [licenceFeatureDetails, setLicenceFeatureDetails] = useState([]);
    const [tier, setTier] = useState([]);
    const [page, setPage] = useState(PAGE_NUMBER);
    const [totalResults, setTotalResults] = useState(0);
    const [scrollLoading, setScrollLoading] = useState(false);
    //const [licenceStatus, setLicenceStatus] = useState('');

    let sortCRD =  {
        sort: 'organizationName',
        order: 'asc'
    };

    const filterServiceAdmin = (data) => {
        let ordID = process.env.REACT_APP_ORG_ID;
        let filterOrg = data.filter((obj) => {
            if(obj.zeissRefOrganizationID != ordID && obj.zeissRefOrganizationID !== 'ServiceAdminGroup') {
                return true;
            }
        });
        return filterOrg;
    }

    useEffect(async () => {
        setError('');

        let token =  await refreshAccessToken();
        if (token && token.accessToken) {
            window.localStorage.setItem('token', token.accessToken);
        }

        let param = { 
            ...sortCRD,
            pageNo: page,
            pageSize: 60 
        };
        let organizationDataUpdate = await GetAndUpdateOrganizationAPI();
        console.log(organizationDataUpdate);
        let organizationData = await TenantAPI(param);
        if(organizationData.responseCode === 200) {
            let data = filterServiceAdmin(organizationData.organizations);
            setTotalResults(organizationData.totalResults);
            setTenantsData(data);
        }
        if(organizationData.responseCode === 400) {
            setTotalResults(0);
            setTenantsData([]);
        }
    }, []);
    
    const handleClose = () => setShow(false);
    const handleDeleteClose = () => setShowDeleteModal(false);

    const handleShow = (selectedOrganization, type) => {
        setError('');
        setShow(true);
        setMode(type);
        if(type === 'Create') {
            let tempOrgDetails = {
                organizationName: '',
                description: '',
                contactFirstName: '',
                contactLastName: '',
                emailAddress: ''
            }
            setSelectedOrganization(tempOrgDetails);
        } else {
            setSelectedOrganization(selectedOrganization);
        }
    }

    const handleDeleteModalShow = (selectedOrganization) => {
        setShowDeleteModal(true);
        setSelectedOrganization(selectedOrganization);
    }

    const validateForm = () => {
        let tempError = true;
        if(selectedOrganization.organizationName === '') {
            let tempErrorMsg = 'Please enter organization name';
            setError(tempErrorMsg);
            setIsFormError(true);
            tempError = false;
            return;
        } else {
            setIsFormError(false);
            tempError = true; 
        } 

        if(selectedOrganization.organizationName) {
            let pattern = new RegExp(/^[a-z\d\-_.,\s]+$/i);
            if (!pattern.test(selectedOrganization.organizationName)) {
                setError('Please enter valid organization name');
                setIsFormError(true);
                tempError = false;
                return;
            }
        }  

        if(selectedOrganization.description) {
            let pattern = new RegExp(/^[a-z\d\-_.,\s]+$/i);
            if (!pattern.test(selectedOrganization.description)) {
                setError('Please enter valid description');
                setIsFormError(true);
                tempError = false;
                return;
            }
        } 

        if(selectedOrganization.contactFirstName === '') {
            let tempErrorMsg = 'Please enter first name';
            setError(tempErrorMsg);
            setIsFormError(true);
            tempError = false;
            return;
        } else {
            setIsFormError(false);
            tempError = true; 
        } 

        if(selectedOrganization.contactFirstName) {
            let pattern = new RegExp(/^[a-z\d\-_.,\s]+$/i);
            if (!pattern.test(selectedOrganization.contactFirstName)) {
                setError('Please enter valid first name');
                setIsFormError(true);
                tempError = false;
                return;
            }
        } 

        if(selectedOrganization.contactLastName === '') {
            let tempErrorMsg = 'Please enter last name';
            setError(tempErrorMsg);
            setIsFormError(true);
            tempError = false;
            return;
        } else {
            setIsFormError(false);
            tempError = true; 
        }

        if(selectedOrganization.contactLastName) {
            let pattern = new RegExp(/^[a-z\d\-_.,\s]+$/i);
            if (!pattern.test(selectedOrganization.contactLastName)) {
                setError('Please enter valid last name');
                setIsFormError(true);
                tempError = false;
                return;
            }
        }  

        if (selectedOrganization.emailAddress === '') {
            let tempErrorMsg = 'Please enter email address';
            setError(tempErrorMsg);
            setIsFormError(true);
            tempError = false;
            return;
        } else {
            setIsFormError(false);
            tempError = true; 
        } 
        if(selectedOrganization.emailAddress) {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(selectedOrganization.emailAddress)) {
                setError('Please enter valid email address');
                setIsFormError(true);
                tempError = false;
                return;
            }
        } 

        if(tempError) {
            console.log(isFormError);
            setIsFormError(false);
        }

        return tempError;
    }

    const createOrganization = async () => {
        let errRes = validateForm();
        if(errRes) {
            let param;
            if(mode === 'Create') {
                param = {
                    organizationID: mode === 'Create' ? 0 : selectedOrganization.organizationID,
                    description: selectedOrganization.description,
                    contactFirstName: selectedOrganization.contactFirstName,
                    contactLastName: selectedOrganization.contactLastName,
                    emailAddress: selectedOrganization.emailAddress,
                    organizationName: selectedOrganization.organizationName
                }
            } else {
                param = {
                    organizationID: mode === 'Create' ? 0 : selectedOrganization.organizationID,
                    description: selectedOrganization.description,
                    contactFirstName: selectedOrganization.contactFirstName,
                    contactLastName: selectedOrganization.contactLastName,
                    emailAddress: selectedOrganization.emailAddress,
                    organizationName: selectedOrganization.organizationName,
                    zeissRefOrganizationID: selectedOrganization.zeissRefOrganizationID,
                    organizationLicenses: licenceFeatureDetails
                }
            }
    
            let responseData = await CreateOrganizationAPI(param, mode);
            if(responseData.data.statusCode === 200) {
                if(responseData.data.status === 'ERROR') {
                    setIsFormError(true);
                    setError(responseData.data.message);
                } else {
                    setShow(false);
                    fetchMoreListItems(page);
                }
            } else {
                if(responseData.responseCode === 401) {
                    setIsFormError(true);
                    setError('Unauthorized');
                } else {
                    setIsFormError(true);
                    setError(responseData.data.title);
                }
            }
        }
    };

    const handleInputChange = (event, name) => {
        const value = event.target.value;
        setSelectedOrganization({
            ...selectedOrganization,
            [name]: value
        });
    };

    const deleteOrganization = async () => {
        setError('');
        let param = {
            organizationName: selectedOrganization.organizationName,
            organizationID: selectedOrganization.organizationID,
            zeissRefOrganizationID: selectedOrganization.zeissRefOrganizationID
        };
        let responseData = await DeleteOrganizationAPI(param);
        if(responseData.data.statusCode === 200) {
            if(responseData.data.status === 'ERROR') {
                setShowDeleteModal(true);
                setError(responseData.data.message);
            } else {
                setShowDeleteModal(false);
                fetchMoreListItems(page);
            }
        } else {
            setIsFormError(true);
            setError(responseData.data.title);
        }
    };

    const handleTabChange = async(key) => {
        if(key === 'licence') {
            let param = {
                organizationID: selectedOrganization.zeissRefOrganizationID,
            };
            let responseData = await GetOrganizationLicenceDetails(param);
            if(responseData) {
                setLicenceData(responseData.licenceData);
                setTier(responseData.licenceData.tierDatas[0]);
                if(responseData.licenceData && responseData.licenceData.organizationLicenses.length > 0) {
                    if(responseData.licenceData.organizationLicenses[0].expirationDate) {
                        let str = responseData.licenceData.organizationLicenses[0].expirationDate;
                        let dateTemp = moment(str).toDate();
                        setStartDate(dateTemp);
                    }
                    
                }
            }
        }
    };

    const handleDateChange = (obj, date) => {
        let data = licenceData.organizationLicenses.map((licObj) => {
            if(licObj.featureName === obj.featureName) {
                let newObj = {
                    tierFeatureID: licObj.tierFeatureID,
                    expirationDate: moment(date).toISOString(),
                    createdBy: config.userId,
                }
                return newObj;
            }
        });
        let dateTemp = moment(date).toDate();
        setStartDate(dateTemp);
        setLicenceFeatureDetails(data);
    }

    const getLicenceStatus = (licence) => {
        let status = '';
        let now = moment().format('YYYY-MM-DD');
        let expDate = licence.expirationDate;
        expDate = moment(expDate).format('YYYY-MM-DD');
        let result = moment(now).isAfter(moment(expDate));
        if(result) {
            status = 'Expired';
            //setLicenceStatus('Expired');
        } else {
            status = 'Active';
            //setLicenceStatus('Active');
        }
        return status;
    }

    const fetchMoreListItems = async(pageno) => {
        setScrollLoading(true);
        let pageNumber = pageno ? pageno : page;

        let param = { 
            sort: 'organizationName',
            order: 'asc',
            pageNo: pageNumber,
            pageSize: 60,
        };
        
        let organizationDataTemp = await TenantAPI(param);
        if(organizationDataTemp.responseCode === 200) {
            let data = filterServiceAdmin(organizationDataTemp.organizations);
            setTotalResults(organizationDataTemp.totalResults);
            setScrollLoading(false);
            if(organizationDataTemp.pageNo > 1) {
                let ids = new Set(organizationsData.map(d => d.organizationID));
                let mergedJobs = [...organizationsData, ...data.filter(d => !ids.has(d.organizationID))];
                setTenantsData(mergedJobs);
                setScrollLoading(false);
            } else {
                let data = filterServiceAdmin(organizationDataTemp.organizations);
                setTenantsData(data);
                setScrollLoading(false);
            }
        }
    }

    return (
        <> 
            <Modal show={showDeleteModal} onHide={handleDeleteClose}  backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Organization</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='tenant-container'>
                        <p>Are you sure you want to delete {selectedOrganization.organizationName} ?</p>
                    </div> 
                    {error ? <div className='error-message'>{error}</div> : ''}
                </Modal.Body>
                <Modal.Footer>
                    <ZuiButton emphasis='active' onClick={handleDeleteClose}>Cancel</ZuiButton>
                    <ZuiButton emphasis='active-primary' onClick={deleteOrganization}>Delete</ZuiButton>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}  backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{mode === 'Create' ? 'Register Organization' : 'Edit Organization'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="home" className="mb-3" onSelect={handleTabChange}>
                        <Tab eventKey="home" title="Info">
                            <div className='tenant-container'>
                                <div className='input-container'>
                                    <label className='organization-label-text'>Organization Name : <span style={{color: 'red'}}>*</span></label>
                                    <input type='text' disabled={mode === 'Create' ? false : true} onChange={(event) => handleInputChange(event, 'organizationName')} value={selectedOrganization.organizationName}/>
                                </div>
                                <div className='input-container'>
                                    <label className='organization-label-text'>Description:</label>
                                    <input type='text' onChange={(event) => handleInputChange(event, 'description')} value={selectedOrganization.description}/>
                                </div>
                                <div className='input-container'>
                                    <label className='organization-label-text'>First Name: <span style={{color: 'red'}}>*</span></label>
                                    <input type='text' onChange={(event) => handleInputChange(event, 'contactFirstName')} value={selectedOrganization.contactFirstName}/>
                                </div>
                                <div className='input-container'>
                                    <label className='organization-label-text'>Last Name: <span style={{color: 'red'}}>*</span></label>
                                    <input type='text' onChange={(event) => handleInputChange(event, 'contactLastName')} value={selectedOrganization.contactLastName}/>
                                </div>
                                <div className='input-container'>
                                    <label className='organization-label-text'>Email Address: <span style={{color: 'red'}}>*</span></label>
                                    <input type='text' onChange={(event) => handleInputChange(event, 'emailAddress')} value={selectedOrganization.emailAddress}/>
                                </div>
                                {error ? <div className='error-message'>{error}</div> : ''}
                            </div>
                        </Tab>
                        <Tab eventKey="licence" title="License" disabled={mode === 'Create'}>
                            <div className='feature-dropdown'>
                                <div>Tier</div>
                                {licenceData && licenceData.tierDatas && 
                                    <div className='wrap'>
                                        <div className="select">
                                            <select className='licence-new-select select-text' defaultValue={tier.tierName}>
                                                {licenceData.tierDatas.map((e, key) => {
                                                    return <option key={key} value={e.tierName}>{e.tierName}</option>
                                                })}
                                            </select>
                                            <span className="select-bar"></span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='licence-feature-text'>License Features</div>
                            <div>
                                <table className='tabel tenant-list-table' aria-labelledby='tabelLabel'>
                                    <thead className='tenants-list-info-header'>
                                        <tr>
                                            <SortingHeader customCss='crd-table-patient-id' id='name' title='Feature' />
                                            <SortingHeader customCss='crd-table-patient-name' id='emailAddress' title='Status' />
                                            <SortingHeader customCss='crd-table-diagnosis' id='roleID' title='Expiration' />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {licenceData &&  licenceData.organizationLicenses && licenceData.organizationLicenses.length > 0 ? (licenceData.organizationLicenses).map((licence) => {
                                            let statusTemp = getLicenceStatus(licence);
                                            return (
                                                <React.Fragment key={`tr-${licence.feature}`}>
                                                    <tr className="crd-table-row job-row">
                                                        <td className='crd-table-patient-id'>
                                                            <PlainTextData value={licence.featureName} subValue={licence.featureName} />
                                                        </td>
                                                        <td className='crd-table-patient-name'>
                                                            <PlainTextData value={statusTemp} subValue={statusTemp}/>
                                                        </td>
                                                        <td className='crd-table-diagnosis date-picker-content'>
                                                            <div  className='date-picker'>
                                                                <DatePicker selected={startDate} onChange={(date) => handleDateChange(licence,date)} dateFormat="MM/dd/yyyy"/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        }) : 
                                            ''
                                        }
                                    </tbody>
                                </table>
                                {licenceData && licenceData.organizationLicenses && licenceData.organizationLicenses.length == 0 && 
                                    <div className='lic-no-data'>
                                        <span>No data found.</span>
                                    </div>
                                }
                            </div>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <ZuiButton emphasis='active' onClick={handleClose}>Cancel</ZuiButton>
                    <ZuiButton emphasis='active-primary' onClick={createOrganization}>Save</ZuiButton>
                </Modal.Footer>
            </Modal>
            <div className='tenants-main-container'>
                <div className='tenant-list-header'>
                    <p className='header-text'>Organization Management</p>
                    <div className='button-container'  data-tip='Register Organization' data-for="registerTip">
                        <ZuiIconPlus size='l' onClick={() => handleShow(selectedOrganization, 'Create')}/>
                    </div>
                    <ReactToolTip 
                        place='bottom'
                        effect='solid'
                        id="registerTip"></ReactToolTip>
                </div>
                <TenantList scrollLoading={scrollLoading} setScrollLoading={setScrollLoading} totalResults={totalResults} page={page} setPage={setPage} fetchMoreListItems={fetchMoreListItems} filterServiceAdmin={filterServiceAdmin} organizationsData={organizationsData} onClick={handleShow} handleDeleteModalShow={handleDeleteModalShow}/>
            </div>
        </>
    )
}