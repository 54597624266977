import React, { useState, useEffect, useRef } from 'react';
import JobStatusList from './JobStatusList';
import { SortingHeader } from '../../common/components/SortingHeader';
import Modal from 'react-bootstrap/Modal';
import '../styles/JobStatus.css';
import { ZuiButton } from "@zeiss/zui-react";
import { GetOrganizationAPI, GetUsersAPI } from '../../users/api/UserAPI';
import PlainTextData from '../../common/components/PlainTextData';
import { GetAllJobsAPI, GetJobStatusAPI, GetJobAllFiles, GetFileStatusLookup, cancelJobAPI } from '../api/JobStatusAPI';
import moment from 'moment';
import {downloadFileFromBlob} from '../../azure-storage-blob';
import { GetSASToken } from '../../import files/api/ImportFileAPI';
import { Spinner, Button } from 'react-bootstrap';
import { refreshAccessToken } from '../../utils/APIUtils';
import { config } from '../../utils/Config';
import ReactToolTip from 'react-tooltip';
import '../../common/styles/SelectCustom.css';
//import { saveAs } from 'file-saver';

export const JobStatus = () => {
    const PAGE_NUMBER = 1;
    const [show, setShow] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedJob, setSelectedJob] = useState({});
    const [organizationsData, setOrganizationsData] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [jobsData, setJobsData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [jobStatusData, setJobStatusData] = useState([]);
    const [selectedJobStatus, setSelectedJobStatus] = useState([]);
    const [getFilesByJob, setFilesByJob] = useState([]);
    const [fileStatus, setFileStatus] = useState([]);
    const [selectedFileStatus, setSelectedFileStatus] = useState({fileStatus: 'Completed', fileStatusID:3});
    const [selectAll, setSelectedAll] = useState(true);
    const [sasToken, setSasToken] = useState('');
    //const [disableCancelButton, setDisableCancelButton] = useState(false);
    //const [disableCompleteButton, setDisableCompleteButton] = useState(false);
    const [page, setPage] = useState(PAGE_NUMBER);
    const [totalResults, setTotalResults] = useState(0);
    const [downloadError, setDownloadError] = useState('');
    const [downloading, setDownloading] = useState(false);
    const [completedFileCount, setCompletedFileCount] = useState(0);
    const [failledFileCount, setFailedFileCount] = useState(0);
    const [cancelFileCount, setCancelFileCount] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [disableCancelButton, setDisableCancelButton] = useState(false);
    const savedCallback = useRef();
    let userRole = window.localStorage.getItem('role');
    const [disableDownloadSelected, setDisableDownloadSelecte] = useState(false);
    const [actualResults, setActualResults] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    let [records, setRecords] = useState(10);
    const [scrollLoading, setScrollLoading] = useState(false);
    const [scrollJobLoading, setScrolJobLoading] = useState(false);
    const [totalJobResults, setTotalJobResults] = useState(0);
    const [totalJobModalResults, setTotalJobModalResults] = useState(0);
    const [jobPage, setJobPage] = useState(1);
    const [dateDiff, setDateDiff] = useState('');
    const [loadingJobData, setLoadingJobData] = useState(true);
    const divRef = useRef(null);
    const [dataDeleted, setDataDeleted] = useState('');
    const arBodyContentRef = useRef(null);
    const [jobSortAscending, setJobSortAscending] = useState('desc');
    const [jobSortBy, setJobSortBy] = useState('createdDate');
    
    const onScroll = () => {
        setScrolJobLoading(false);
        let scrollBottom = arBodyContentRef.current.scrollHeight - arBodyContentRef.current.scrollTop - arBodyContentRef.current.clientHeight;
        scrollBottom = Math.floor(Math.abs(scrollBottom)) <= 0;
        if (scrollBottom)  {
            setScrolJobLoading(true);
            loadMoreExams();
        }
    }

    function loadMoreExams() {
        if (getFilesByJob.length >= totalJobResults) {
            setScrolJobLoading(false);
            return;
        } 
        if(getFilesByJob.length == 0) return;
        setJobPage(jobPage + 1);
        (async () => { 
            await fetchMoreJobs(jobPage + 1); 
        })();
    }

    useEffect(async ()  => {
        let token =  await refreshAccessToken();
        if (token && token.accessToken) {
            window.localStorage.setItem('token', token.accessToken);
        }
        if(config && config.organizationID) {
            let param =  {
                organizationID: config.organizationID, //ToDo: Need to change the organization id once zeiss id integrated 
                duration: 8888
            };
            let fileData = await GetSASToken(param);
            if(fileData.result && fileData.result.saSToken) {
                setSasToken(fileData.result.saSToken);
            }
        } else {
            alert('organization details are not present');
        }
    }, []);

    const fetchMoreListItems = (page) => {
        getJobsByUserAndJobs(selectedOrganization, selectedUser, selectedJobStatus, page, 60, 'scroll', jobSortBy, jobSortAscending);
    }

    const getOrganizationsByUserRole = (org) => {
        if(userRole === 'Tenant-Admin') {
            if(org.dbStatus === 2 && org.zeissRefOrganizationID === config.organizationID)
                return org;
        }
        if(userRole === 'Zeiss-Admin' || userRole === 'ZEISS-Admin' || userRole === 'ZEISS_Admin') {
            if(org.dbStatus === 2 && org.zeissRefOrganizationID === config.organizationID) {
                return org;
            }
        }
    }

    useEffect(async () => {
        if(userRole === 'Zeiss-Admin' || userRole === 'ZEISS-Admin' || userRole === 'ZEISS_Admin') {
            let orgSortCRD =  {
                sort: 'organizationName',
                order: 'asc'
            };
            let organizationData = await GetOrganizationAPI(orgSortCRD);
            if(organizationData.responseCode === 200) {
                let organizations = organizationData.organizations.filter((org) => {
                    let data = getOrganizationsByUserRole(org);
                    return data;
                });
                if(userRole === 'Zeiss-Admin' || userRole === 'ZEISS-Admin' || userRole === 'ZEISS_Admin') {
                    setOrganizationsData(organizationData.organizations);
                    setOrganizationsData(organizations);
                    if(config && config.organizationID) {
                        let tempOrg = organizationData.organizations.filter((org) => {
                            if(org.zeissRefOrganizationID === config.organizationID) { //ToDo: Once zeiss Id integration done need to update the organization ID here
                                return org;
                            }
                        });
                        if(userRole === 'Zeiss-Admin' || userRole === 'ZEISS-Admin' || userRole === 'ZEISS_Admin') {
                            setOrganizationsData(organizationData.organizations);
                        } else {
                            setOrganizationsData(organizations);
                        }
                        setSelectedOrganization(tempOrg[0]);
                        getUsersByOrg(tempOrg[0]);
                    }
                }
            }
        } else {
            let orgID = localStorage.getItem('organizationID');
            let name = localStorage.getItem('organizationName');
            if((config && config.organizationID) || orgID) {
                let organizations = [{organizationName: config.organizationName || name , zeissRefOrganizationID: config.organizationID || orgID }];
                setOrganizationsData(organizations);
                setSelectedOrganization(organizations[0]);
                getUsersByOrg(organizations[0]);
            }
        }
    }, []);

    useInterval(async() => {
        getJobsByUserAndJobs(selectedOrganization, selectedUser, selectedJobStatus, 1, page*60, 'UI', jobSortBy, jobSortAscending);
    }, 60000); // 60 seconds interval
    
    function useInterval(callback, delay) {
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);
        
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    const getJobStatusbyOrg = async(organization, user) => {
        let jobsSortCRD = {
            sort: 'jobStatus',
            order: 'asc'
        }

        let jobStatusParam = { 
            ...jobsSortCRD,
            organizationID: organization.zeissRefOrganizationID
        };

        let jobStatus = await GetJobStatusAPI(jobStatusParam);
        if(jobStatus.responseCode == 200) {
            let tempJobStatus = jobStatus.jobstatus;
            tempJobStatus.unshift({jobStatus: 'ALL', jobStatusID: 0});
            setJobStatusData(tempJobStatus);
            setSelectedJobStatus({jobStatus: 'ALL', jobStatusID: 0});
            
            getJobsByUserAndJobs(organization, user, tempJobStatus[0], 1, 60, 'UI', jobSortBy, jobSortAscending);
        } else {
            setJobStatusData({jobStatus: 'ALL', jobStatusID: 0});
            setSelectedJobStatus({jobStatus: 'ALL', jobStatusID: 0});
            getJobsByUserAndJobs(organization, user, {jobStatus: 'ALL', jobStatusID: 0}, 1, 60, 'UI', jobSortBy, jobSortAscending);
        }
    }

    const getUsersByOrg = async(organization) => {
        let tempUserData;
        if(userRole != 'Tenant-Non-Admin') {
            let userSortCRD = {
                sort: 'name',
                order: 'asc'
            }

            let usersParam = { 
                ...userSortCRD,
                organizationID: organization.zeissRefOrganizationID,
                pageNo: 1,
                pageSize: 10000
            };
            let userData = await GetUsersAPI(usersParam);
            //let email = window.localStorage.getItem('email');
            tempUserData = userData.users.filter((userObj) => {
                if(userObj.zeissRefAccountID > 0) {
                    return userObj;
                }
            }).map((user) => {
                let displayUserName = user.firstName + ' ' + user.lastName;
                user.displayUserName = displayUserName;
                return user;
            });
            setUsersData(tempUserData);
            if(tempUserData.length > 0) {
                if(userRole == 'Tenant-Non-Admin') {
                    setSelectedUser(tempUserData[0]);
                } else {
                    setSelectedUser({ displayUserName: 'Select User', userID: -1, zeissRefAccountID: -1 });
                    tempUserData.unshift({ displayUserName: 'Select User', userID: -1, zeissRefAccountID: -1 });
                }
            } else {
                let tempObj = { displayUserName: 'Select User', userID: -1, zeissRefAccountID: -1 };
                setSelectedUser(tempObj);
                tempUserData.unshift(tempObj);
            }
            getJobStatusbyOrg(organization, tempUserData[0]);
        } else {
            let userId = config.userId || localStorage.getItem('userId');
            let userAccountId = config.userAccountId || localStorage.getItem('userAccountId');
            let userFirstName = config.userFirstName || localStorage.getItem('userFirstName') ;
            let userLastName = config.userLastName || localStorage.getItem('userLastName');
            let email = window.localStorage.getItem('email');
            if((config && config.organizationID) || userAccountId) {
                let displayUserNameTemp = userFirstName  + ' ' +  userLastName;
                let tempUserData = [{displayUserName: displayUserNameTemp, firstName: userFirstName, lastName: userLastName, userID: userId, zeissRefAccountID: userAccountId,  emailAddress: email}];
                setUsersData(tempUserData);
                setSelectedUser(tempUserData[0]);
                getJobStatusbyOrg(organization, tempUserData[0]);
            }
        }
    };

    const handleClose = () => {
        setShow(false);
        setSelectedFileStatus([]);
    }

    const handleDownloadAll = async(selectedJobs) => {
        if(selectedJobs.processedJobFileName && !selectedJobs.downloaded) {
            //updateJobStatus(selectedJobs, true);
            let downloadResponse = await downloadFileFromBlob(sasToken, selectedJobs.processedJobFileName);
            if(downloadResponse.status === 200) {
                const anchor = document.createElement('a');
                anchor.href = downloadResponse.url;
                anchor.download = selectedJobs.processedJobFileName;
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
            } 
        }
    };

    const getFileCount = (fileData) => {
        setFailedFileCount(0);
        setCompletedFileCount(0);
        setCancelFileCount(0);
        setTotalFiles(fileData.length);
        console.log(totalFiles);
        let count = 0;
        if(fileData.length > 0 && fileData[0].lstFilesStatusCount.length > 0) {
            fileData[0].lstFilesStatusCount.forEach((fileObj) => {
                if(fileObj.fileStatus == 'Completed') {
                    setCompletedFileCount(fileObj.filesCount);
                }
                if(fileObj.fileStatus == 'Failed') {
                    setFailedFileCount(fileObj.filesCount);
                }
                if(fileObj.fileStatus == 'New') {
                    count = count + fileObj.filesCount;
                    setCancelFileCount(count);
                }
                if(fileObj.fileStatus == 'Processing') {
                    count = count + fileObj.filesCount;
                    setCancelFileCount(count);
                }
            });
        }
    };

    const fetchMoreJobs = async (pageno) => {
        setScrolJobLoading(true);
        let pageNumber = pageno ? pageno : jobPage;
        let param = {
            sort: 'processedFileName',
            order: 'asc',
            organizationID: selectedOrganization.zeissRefOrganizationID,
            jobID: selectedJob.jobID,
            fileStatusID: selectedFileStatus.fileStatusID,
            pageNo: pageNumber,
            pageSize: 60,
            accountID: selectedUser.zeissRefAccountID
        }

        let filesData = await GetJobAllFiles(param);
        setTotalJobResults(filesData.totalResults);
        setScrolJobLoading(false);
        if(filesData.pageNo > 1) {
            let ids = new Set(getFilesByJob.map(d => d.fileID));
            let mergedJobs = [...getFilesByJob, ...filesData.jobs.filter(d => !ids.has(d.fileID))];
            if(mergedJobs.length > 0) {
                let tempData = mergedJobs.map((file) => {
                    file.selected = true;
                    file.disableCheck = false;
                    if(file.processedFileName === 'Analysis Layer - Ignore Download') {
                        file.disableCheck = true;
                        file.selected = false;
                    }
                    return file;
                });
                setFilesByJob(tempData);
            } else {
                setFilesByJob(mergedJobs);
            }
            setScrolJobLoading(false);
        } else {
            if(filesData.jobs.length > 0) {
                let tempData = filesData.jobs.map((file) => {
                    file.selected = true;
                    file.disableCheck = false;
                    if(file.processedFileName === 'Analysis Layer - Ignore Download') {
                        file.disableCheck = true;
                        file.selected = false;
                    }
                    return file;
                });
                setFilesByJob(tempData);
            } else {
                setFilesByJob(filesData.jobs);
            }
            setScrolJobLoading(false);
        }
    };

    const getJobAndFileData = async(selectedJobs) => {
        setFailedFileCount(0);
        setDateDiff('');
        setCompletedFileCount(0);
        setCancelFileCount(0);
        setTotalJobResults(0);
        setTotalJobModalResults(0);
        setSelectedAll(true);
        setSelectedJob(selectedJobs);
        setDisableCancelButton(true);
        setDataDeleted('');
        setSelectedFileStatus({fileStatus: 'Completed', fileStatusID:3});
        setFilesByJob([]);
        if(selectedJobs.destinationConnectionID > -1 ) {
            setDisableDownloadSelecte(true);
        } else {
            if(selectedJobs.isDownloadDisable) {
                setDisableDownloadSelecte(true);
                let downloadSelectedToolTip;
                if(selectedJobs.downloadRetentionDays > 1) {
                    downloadSelectedToolTip = `Data deleted due to retention policy for this job - ${selectedJobs.downloadRetentionDays} days`;
                } else {
                    downloadSelectedToolTip = `Data deleted due to retention policy for this job - ${selectedJobs.downloadRetentionDays} day`;
                }
                setDataDeleted(downloadSelectedToolTip);
            } else {
                setDisableDownloadSelecte(false);
            }
        }
        let fileParam = {
            sort: '',
            order: '',
            organizationID: selectedOrganization.zeissRefOrganizationID,
        }

        let fileStatusData = await GetFileStatusLookup(fileParam);
        if(fileStatusData) {
            let tempFileStatus = fileStatusData.jobstatus;
            tempFileStatus.push({fileStatus: 'ALL', fileStatusID: 0});
            setFileStatus(tempFileStatus);
    
            let filteredFileData = fileStatusData.jobstatus.filter((file) => {
                if(file.fileStatus == 'Completed') {
                    return true;
                }
            });
            setSelectedFileStatus(filteredFileData[0]);
            setSelectedFileStatus({fileStatus: 'Completed', fileStatusID:3});
        }
       
        let param = {
            sort: 'processedFileName',
            order: 'asc',
            organizationID: selectedOrganization.zeissRefOrganizationID,
            jobID: selectedJobs.jobID,
            fileStatusID: 0,
            pageNo: 1,
            pageSize: 60,
            accountID: selectedUser.zeissRefAccountID
        }

        let filesData = await GetJobAllFiles(param);
        setTotalJobResults(filesData.totalResults);
        setTotalJobModalResults(filesData.totalResults);
        getFileCount(filesData.jobs);
        getDateDifference(selectedJobs);
        let tempData = filesData.jobs.map((file) => {
            file.selected = true;
            file.disableCheck = false;
            if(file.processedFileName === 'Analysis Layer - Ignore Download') {
                file.disableCheck = true;
                file.selected = false;
            }
            return file;
        }).filter((file) => {
            if(file.fileStatus === 'Completed') {
                return true;
            }
        });
        setFilesByJob(tempData);

        if(selectedJobs.jobStatus !== 'New' && selectedJobs.jobStatus !== 'Processing') {
            setDisableCancelButton(true);
        } else {
            setDisableCancelButton(false);
        }
    }

    const handleShow = (selectedJobs) =>  {
        setJobPage(1);
        setShow(true);
        getJobAndFileData(selectedJobs);
    }

    const handleCloseCancelModal = () => setShowCancelModal(false);

    const handleCancelAction = async(selectedJobs) => {
        let param = {
            organizationID: selectedOrganization.zeissRefOrganizationID,
            jobID: selectedJobs.jobID,
            modifiedBy: 3
        }

        let filesData = await cancelJobAPI(param);
        setShowCancelModal(false);
        if(filesData.responseCode === 200) {
            getJobsByUserAndJobs(selectedOrganization, selectedUser, selectedJobStatus, page, 60, 'UI', jobSortBy, jobSortAscending);
            setShowCancelModal(false);
        }
    }

    const handleShowCancelModal = () =>  {
        setShow(false);
        setShowCancelModal(true);
    }

    const handleSelectChange = (event) => {
        setPage(1);
        let filteredOrgData = organizationsData.filter((organization) => {
            if(organization.organizationName === event.target.value) {
                return true;
            }
        });
        setSelectedOrganization(filteredOrgData[0]);
        getUsersByOrg(filteredOrgData[0]);
    };

    const getJobsByUserAndJobs = async (orgData, userData, jobData, pageno, pageSize, type, jobSort, jobOrder) => {
        setIsLoading(true);
        if(type == 'scroll') {
            setScrollLoading(true);
        }

        
        let pageNumber = pageno ? pageno : page;
        let param = { 
            sort: jobSort,
            order: jobOrder,
            pageNo: pageNumber,
            pageSize: pageSize,
            organizationID: orgData ? orgData.zeissRefOrganizationID : selectedOrganization.zeissRefOrganizationID,
            accountID: userData ? userData.zeissRefAccountID : selectedUser.zeissRefAccountID,
            jobStatusID: jobData ? jobData.jobStatusID : selectedJobStatus.jobStatusID
        };
        if(param.organizationID) {
            let tempJobsData = await GetAllJobsAPI(param);
            setTotalResults(tempJobsData.totalResults);
            setActualResults(tempJobsData.actualResults);
            setLoadingJobData(false);
            setIsLoading(false);
            if(tempJobsData.pageNo > 1) {
                let ids = new Set(jobsData.map(d => d.jobID));
                let mergedJobs = [...jobsData, ...tempJobsData.jobs.filter(d => !ids.has(d.jobID))];
                if(mergedJobs.length > 0) {
                    let tempData = mergedJobs.map((job) => {
                        if(job.jobStatus !== 'Completed' || job.destinationConnectionID > -1) {
                            job.downloaded = true;
                            job.downloadToolTip = 'Download not supported';
                        } else {
                            if(job.isDownloadDisable) {
                                job.downloaded = true;
                                if(job.downloadRetentionDays > 1) {
                                    job.downloadToolTip = `Data deleted due to retention policy for this job - ${job.downloadRetentionDays} days`;
                                } else {
                                    job.downloadToolTip = `Data deleted due to retention policy for this job - ${job.downloadRetentionDays} day`;
                                }
                                
                            } else {
                                job.downloaded = false;
                                job.downloadToolTip = 'Download All';
                            }
                        }
                        return job;
                    });
                    setJobsData(tempData);
                } else {
                    setJobsData(mergedJobs);
                }
                setScrollLoading(false);
            } else {
                if(tempJobsData.jobs.length > 0) {
                    let tempData = tempJobsData.jobs.map((job) => {
                        if(job.jobStatus !== 'Completed' || job.destinationConnectionID > -1) {
                            job.downloaded = true;
                            job.downloadToolTip = 'Download not supported';
                        } else {
                            if(job.isDownloadDisable) {
                                job.downloaded = true;
                                if(job.downloadRetentionDays > 1) {
                                    job.downloadToolTip = `Data deleted due to retention policy for this job - ${job.downloadRetentionDays} days`;
                                } else {
                                    job.downloadToolTip = `Data deleted due to retention policy for this job - ${job.downloadRetentionDays} day`;
                                }
                            } else {
                                job.downloaded = false;
                                job.downloadToolTip = 'Download All';
                            }
                        }
                        return job;
                    });
                    setJobsData(tempData);
                } else {
                    setJobsData(tempJobsData.jobs);
                }
                setScrollLoading(false);
            }
        }
    };

    const handleSelectUserChange = (event) => {
        setPage(1);
        let filteredUserData = usersData.filter((user) => {
            if(user.displayUserName === event.target.value) {
                return true;
            }
        });
        setSelectedUser(filteredUserData[0]);
        getJobsByUserAndJobs(selectedOrganization, filteredUserData[0], selectedJobStatus, 1, 60, 'UI', jobSortBy, jobSortAscending);
    };

    const handleSelectJobChange = (event) => {
        setPage(1);
        let filteredJobData = jobStatusData.filter((job) => {
            if(job.jobStatus === event.target.value) {
                return true;
            }
        });
        setSelectedJobStatus(filteredJobData[0]);
        getJobsByUserAndJobs(selectedOrganization, selectedUser, filteredJobData[0], 1, 60, 'UI', jobSortBy, jobSortAscending);
    };

    const handleFileStatusChange = async(event, selectedJob) => {
        setScrolJobLoading(false);
        setSelectedFileStatus([]);
        setFilesByJob([]);
        //setFilesByJob([]);
        let value = event.target.value;
        let filteredFileData = fileStatus.filter((file) => {
            if(file.fileStatus === value) {
                return true;
            }
        });
        setSelectedFileStatus(filteredFileData[0]);
        let param = {
            sort: 'processedFileName',
            order: 'asc',
            organizationID: selectedOrganization.zeissRefOrganizationID,
            jobID: selectedJob.jobID,
            fileStatusID: filteredFileData[0].fileStatusID,
            pageNo: 1,
            pageSize: 60,
            accountID: selectedUser.zeissRefAccountID
        }

        let filesData = await GetJobAllFiles(param);
        setTotalJobResults(filesData.totalResults);
        setScrolJobLoading(false);
        let tempData = filesData.jobs.map((file) => {
            file.selected = true;
            file.disableCheck = false;
            if(file.processedFileName === 'Analysis Layer - Ignore Download') {
                file.disableCheck = true;
                file.selected = false;
            }
            return file;
        });
        setFilesByJob(tempData);
    }

    const handleSelectAll = () => {
        setSelectedAll(!selectAll);
        getFilesByJob.forEach((file) => {
            file.selected = !selectAll;
            if(file.processedFileName === 'Analysis Layer - Ignore Download') {
                file.selected = false;
            }
        });
        setFilesByJob(getFilesByJob);
    }

    const selectFile = (event, file) => {
        file.selected = true;
        const value = event.target.checked;
        let fileTemp = [...getFilesByJob];
        let fileTempSelected = fileTemp.map((fileObj) => {
            if(fileObj.fileName === file.fileName) {
                fileObj.selected = value;
                if(fileObj.processedFileName === 'Analysis Layer - Ignore Download') {
                    fileObj.selected = false;
                }
            }
            return fileObj;
        });

        let selectedTempFile = fileTempSelected.filter((fileObjTemp) => {
            if(fileObjTemp.selected) {
                return fileObjTemp;
            }
        });
        if(getFilesByJob.length === selectedTempFile.length) {
            setSelectedAll(true);
        } else {
            setSelectedAll(false);
        }

        setFilesByJob(fileTempSelected);
    }

    

    const getDateDifference = (selectedJob) => {
        let diff;
        if(selectedJob.jobEndTime && selectedJob.jobStartTime) {
            let now  = moment(selectedJob.jobEndTime).format('DD/MM/YYYY H:mm:ss');
            let then = moment(selectedJob.jobStartTime).format('DD/MM/YYYY H:mm:ss');
            // Defining start and end variables
            let start = moment(then, 'DD/MM/YYYY H:mm:ss');
            let end   = moment(now, 'DD/MM/YYYY H:mm:ss');
            // Getting the difference: hours (h), minutes (m) and seconds (s)
            var years = end.diff(start, 'year');
            start.add(years, 'years');
            var months = end.diff(start, 'months');
            start.add(months, 'months');
            var days = end.diff(start, 'days');
            start.add(days, 'days');
            var h = end.diff(start, 'hours');
            start.add(h, 'hours');
            var m = end.diff(start, 'minutes');
            start.add(m, 'minutes');
            var s = end.diff(start, 'seconds');

            if(h & m & s) {
                diff =  h + ' Hours ' + m + ' Minutes ' + s + ' Seconds';
                setDateDiff(diff);
                return;
            }
            if(h & m) {
                diff = h + ' Hours ' + m + ' Minutes';
                setDateDiff(diff);
                return;
            }
            if(m & s) {
                diff=  m + ' Minutes ' + s + ' Seconds';
                setDateDiff(diff);
                return;
            }
            if(h) {
                diff =  h + ' Hours';
                setDateDiff(diff);
                return;
            }
            if(m) {
                diff = m + ' Minutes';
                setDateDiff(diff);
                return;
            }
            if(s) {
                diff =  s + ' Seconds';
                setDateDiff(diff);
                return;
            }
        }
    };

    // const updateFileDownloadStatus = (selectedFile, state) => {
    //     let tempJob = getFilesByJob.map((job) => {
    //         if(job.fileName === selectedFile.fileName) {
    //             job.downloaded = state;
    //             return job;
    //         } else {
    //             return job;
    //         }
    //     });

    //     setFilesByJob(tempJob);

    //     let downloadingJobs = getFilesByJob.filter((jobObj) => {
    //         if(jobObj.downloaded === true) {
    //             return jobObj;
    //         }
    //     });
        
    //     if(downloadingJobs.length > 0) {
    //         setDownloading(true);
    //     } else {
    //         setDownloading(false);
    //     }
    // };

    const handleDownloadAction = async (status) => {
        setDownloadError('');
        setDownloading(false);
        if(status === 'New' || status === 'Processing') {
            return;
        }
        let selectedFileJobs = getFilesByJob.map((fileObj) => {
            fileObj.downloaded = false;
            return fileObj;
        }).filter((file) => {
            if(file.selected) {
                return file;
            }
        });
        for (let file of selectedFileJobs) {
        //let data = selectedFileJobs.map(async (file) => {
            if(file.selected && file.processedFileName) {
                //updateFileDownloadStatus(file, true);
                //const fileExtension = file.processedFileName.split(".").pop();
                let progress = await downloadFileFromBlob(sasToken, file.processedFileName);
                console.log('progress');
                console.log(progress);
                if(progress.status === 200) {
                    console.log('downloading......', file.processedFileName);
                    //saveAs(progress.url, file.processedFileName);
                    const anchor = document.createElement('a');
                    anchor.href = progress.url;
                    anchor.download = file.processedFileName;
                    document.body.appendChild(anchor);
                    anchor.click();
                    document.body.removeChild(anchor);
                }
                // if(progress.status === 200) {
                //     let blob = new Blob([progress.downloaded], {type: '.' + fileExtension});
                //     const url = window.URL.createObjectURL(blob);
                //     const link = document.createElement('a');
                //     link.href = url;
                //     link.setAttribute('download', file.processedFileName);
                //     document.body.appendChild(link);
                //     link.click();
                //     updateFileDownloadStatus(file, false);
                // } else {
                //     updateFileDownloadStatus(file, false);
                //     setDownloadError(progress.error);
                // }
            }
        //});
        //console.log(data);
        }

        let downloadingJobs = getFilesByJob.filter((jobObj) => {
            if(jobObj.downloaded === true) {
                return jobObj;
            }
        });
        
        if(downloadingJobs.length > 0) {
            setDownloading(true);
        } else {
            setDownloading(false);
        }
    };

    const sortFeatures = {
        sortHeader: true,
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}  backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Job Status - {selectedJob.jobStatus} {selectedJob.jobDisplayID}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{marginBottom: 5}}>
                    <div>
                        <p className='job-status-text'>{completedFileCount} of {totalJobModalResults} Complete</p>
                        <p className='job-status-text'>{failledFileCount} failed</p>
                        <p className='job-status-text'>Total time: {dateDiff} </p>
                    </div>
                    <div className='file-status-select'>
                        <div className='wrap'>
                            <div className="select">
                                <select className='config-new-select-job select-text' onChange={(event) => handleFileStatusChange(event, selectedJob)} defaultValue={selectedFileStatus.fileStatus}>
                                    {fileStatus.map((e, key) => {
                                        return <option key={key} selected={e.fileStatus === 'Completed' ? true : false} value={e.fileStatus}>{e.fileStatus}</option>
                                    })}
                                </select>
                                <span className="select-bar"></span>
                            </div>
                        </div>
                    </div>
                    <div className='job-status-container'>
                        <table className='tabel import-file-list-table' aria-labelledby='tabelLabel'>
                            <thead className='importfile-list-info-header'>
                                <tr>
                                    <td colSpan='1' className='checkbox-header'>
                                        <input type='checkbox' checked={selectAll} onChange={handleSelectAll}/>
                                    </td>
                                    <SortingHeader customCss='crd-table-patient-id source-files-header' {...sortFeatures} id='patientID' title='Select Files' />
                                    <SortingHeader customCss='crd-table-patient-id source-files-header' {...sortFeatures} id='patientID' title='Status' />
                                </tr>
                            </thead>
                            <tbody ref={arBodyContentRef} onScroll={onScroll} className='file-status-modal-tbody'>
                                {getFilesByJob.length === 0 ? <tr style={{display: 'grid'}}><td colSpan={10}><p className='files-not-selected'>No file found</p></td></tr> : ''}
                                {(getFilesByJob).map((job) => {
                                    return (
                                        <React.Fragment key={`tr-${job.fileID}`}>
                                            <tr className="crd-table-row">
                                                <td className='crd-table-caret-icon'>
                                                    <input type='checkbox' disabled={job.processedFileName === 'Analysis Layer - Ignore Download'} checked={job.selected} onChange={(event) => selectFile(event, job)} defaultChecked={job.selected} />
                                                </td>
                                                <td className='crd-table-patient-id filename-list'>
                                                    <PlainTextData value={job.fileStatus === 'Completed' ? job.processedFileName : job.fileName} />
                                                </td>
                                                <td className='crd-table-patient-id filename-list'>
                                                    <PlainTextData value={job.fileStatus} />
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })}
                            </tbody>
                            {scrollJobLoading && 
                                <div className='loading-job-text'>
                                    Loading data...
                                </div>
                            }
                        </table>
                        <div className='download-failed'>{downloadError && <span>Download Failed.</span>}</div>
                    </div> 
                </Modal.Body>
                <Modal.Footer>
                    <ZuiButton  disabled={disableCancelButton} emphasis='active' onClick={() => handleShowCancelModal()}>Cancel Job</ZuiButton>
                    <div data-tip={dataDeleted} data-for="registerTip">
                        <Button className="download-button" variant="primary"   onClick={() => handleDownloadAction(selectedFileStatus.fileStatus)} disabled={selectedFileStatus.fileStatus !== 'Completed' || downloading || disableDownloadSelected}>
                            {downloading && <Spinner animation="border" size="sm" role="status"></Spinner>}
                            <span style={{marginLeft: 10}}>Download Selected</span>
                        </Button>
                    </div>
                    <ReactToolTip 
                        place='bottom'
                        effect='solid'
                        id="registerTip">
                    </ReactToolTip>
                </Modal.Footer>
            </Modal>
            <Modal show={showCancelModal} onHide={handleCloseCancelModal}  backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Job</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Are you sure you want to cancel job {selectedJob.jobDisplayID} ?</p>
                        <p style={{fontSize:13, color:'red', textAlign:'center'}}>{cancelFileCount} files remaining</p>
                    </div> 
                </Modal.Body>
                <Modal.Footer>
                    <ZuiButton emphasis='active' onClick={handleCloseCancelModal}>No</ZuiButton>
                    <ZuiButton emphasis='active-primary' onClick={() => handleCancelAction(selectedJob)}>Yes</ZuiButton>
                </Modal.Footer>
            </Modal>
            <div ref={divRef} className='job-status-main-container'>
                <div className='tenant-list-header'>
                    <div>
                        <p className='header-text'>Job & File Status</p>
                    </div>
                    <div className='icon-container'>
                        {userRole !== 'Tenant-Non-Admin' && 
                            <div className='wrap'>
                                <div className="select">
                                    <select className='select-text' onChange={(event) => handleSelectChange(event, 'organizationName')} defaultValue={selectedOrganization ?  selectedOrganization.organizationName: ''} value={selectedOrganization ?  selectedOrganization.organizationName: ''}>
                                        {organizationsData.map((e, key) => {
                                            return <option selected={true} key={key} value={e.organizationName}>{e.organizationName}</option>;
                                        })}
                                    </select>
                                    <span className="select-bar"></span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='icon-container'>
                        <div className='wrap'>
                            <div className="select">
                                <select placeholder='Select User' className='select-organization select-text' onChange={(event) => handleSelectUserChange(event, 'organizationName')} value={selectedUser.displayUserName} defaultValue={selectedUser.displayUserName}>
                                    {usersData.map((e, key) => {
                                        //let value = e.firstName + ' ' + e.lastName;
                                        return <option selected={true} key={key} value={e.displayUserName}>{e.displayUserName}</option>
                                    })}
                                </select>
                                <span className="select-bar"></span>
                            </div>
                        </div>
                    </div>
                    <div className='icon-container'>
                        <div className='wrap'>
                            <div className="select">
                                <select placeholder='Select Status' className='select-organization select-text' onChange={(event) => handleSelectJobChange(event, 'organizationName')} value={selectedJobStatus.jobStatus} defaultValue={selectedJobStatus.jobStatus}>
                                    {jobStatusData.map((e, key) => {
                                        return <option selected={true} key={key} value={e.jobStatus}>{e.jobStatus}</option>
                                    })}
                                </select>
                                <span className="select-bar"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <JobStatusList getJobsByUserAndJobs={getJobsByUserAndJobs} setJobSortBy={setJobSortBy} setJobSortAscending={setJobSortAscending} loadingJobData={loadingJobData} scrollLoading={scrollLoading} setScrollLoading={setScrollLoading} divRef={divRef} records={records} setRecords={setRecords} isLoading={isLoading} actualResults={actualResults} totalResults={totalResults} page={page} setPage={setPage} fetchMoreListItems={fetchMoreListItems} selectedOrganization={selectedOrganization} selectedUser={selectedUser} selectedJobStatus={selectedJobStatus} jobsData={jobsData} onClick={handleShow} handleDownloadAll={handleDownloadAll} onCancel={handleShowCancelModal}/>
            </div>
        </>
    )
}