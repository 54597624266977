import { APIUtils } from '../../utils/APIUtils';
import { getAppInsights } from '../../TelemetryService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export async function GetConfigurationAPI(param) {
    let appInsights = getAppInsights();
    let responseData = {
        pageNo: param.pageNo,
        pageSize: param.pageSize,
        sortColumn: param.sort,
        sortOrder: param.order,
        organizationID: param.organizationID
    }

    await APIUtils('CloudConfiguration/GetCloudConfiguration').postCall(responseData)
        .then((result) => {
            console.log(result);
            responseData.responseCode = result.status;
            responseData.totalResults = result.data.totalResults;
            responseData.configurations = result.data.result;
            appInsights.trackTrace({ message: 'GetConfigurationAPI', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetConfigurationAPI' } });
            }
        });
    return responseData;
}

export async function CreateCloudConfigurationAPI(param) {
    let appInsights = getAppInsights();
    let responseData = {
        cloudConfigurationID: param.cloudConfigurationID,
        connectionType: param.connectionType,
        connectionName: param.connectionName,
        connectionStringValue: param.connectionStringValue,
        createdBy: param.createdBy,
        organizationID: param.organizationID
    };

    await APIUtils('CloudConfiguration/CreateCloudConfiguration').postCall(responseData)
        .then((result) => {
            console.log(result);
            responseData.data = result.data;
            responseData.responseCode = result.status;
            appInsights.trackTrace({ message: 'CreateCloudConfigurationAPI', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'CreateCloudConfigurationAPI' } });
            }
        });
    return responseData;
}

export async function GetPlainCloudConfigurationString(param) {
    let appInsights = getAppInsights();
    let responseData = {
        cloudConfigurationID: param.cloudConfigurationID,
        organizationID: param.organizationID
    };

    await APIUtils('CloudConfiguration/GetPlainCloudConfigurationString').postCall(responseData)
        .then((result) => {
            console.log(result);
            responseData.responseCode = result.status;
            responseData.data = result.data.result;
            appInsights.trackTrace({ message: 'GetPlainCloudConfigurationString', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetPlainCloudConfigurationString' } });
            }
        });
    return responseData;
}

export async function DeleteCloudConfiguration(param) {
    let appInsights = getAppInsights();
    let responseData = {
        organizationID: param.organizationID,
        cloudConfigurationID: param.cloudConfigurationID,
        modifiedBy: param.modifiedBy,
    };

    await APIUtils('CloudConfiguration/DeleteCloudConfiguration').postCall(responseData)
        .then((result) => {
            console.log(result);
            responseData.data = result.data;
            responseData.responseCode = result.status;
            appInsights.trackTrace({ message: 'DeleteCloudConfiguration', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'DeleteCloudConfiguration' } });
            }
        });
    return responseData;
}
