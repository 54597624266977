import React, { useState, useEffect } from 'react';
import ConfigurationList from '../components/ConfigurationList';
import Modal from 'react-bootstrap/Modal';
import { ZuiIconPlus, ZuiButton } from "@zeiss/zui-react"
import '../styles/Configuration.css';
import ReactToolTip from 'react-tooltip';
import { refreshAccessToken } from "../../utils/APIUtils";
import { CreateCloudConfigurationAPI, GetConfigurationAPI, DeleteCloudConfiguration, GetPlainCloudConfigurationString } from '../api/ConfigurationAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/fontawesome-free-solid';
import { config } from '../../utils/Config';
import '../../common/styles/SelectCustom.css';

export const Configuration = () => {
    const PAGE_NUMBER = 1;
    const [show, setShow] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedConfiguration, setSelectedConfiguration] = useState({
        cloudName: '',
        connectionName: '',
        storageAccountKey: '',
        storageAccountName: '',
        blobContainer: '',
        azurePath: '',
        awsConnectionName: '',
        accessKeyId: '',
        secretAccessKey: '',
        bucket: '',
        region: '',
        awsPath: '',
    });
    const [configurationData, setConfigurationData] = useState([]);
    const [mode, setMode] = useState('Create');
    const [error, setError] = useState('');
    const [isFormError, setIsFormError] = useState('');
    const [selectedCloud, setSelectedCloud] = useState({ cloudName: 'Microsoft Azure', connectionType: 1 });
    const [showPassword, setShowPassword] = useState(true);
    const [page, setPage] = useState(PAGE_NUMBER);
    const [totalResults, setTotalResults] = useState(0);
    const [scrollLoading, setScrollLoading] = useState(false);

    const handleDeleteModalShow = (selectedConfiguration) => {
        setShowDeleteModal(true);
        setSelectedConfiguration(selectedConfiguration);
    }
    const handleDeleteClose = () => setShowDeleteModal(false);
    const handleClose = () => setShow(false);

    let sortCRD =  {
        sort: 'connectionName',
        order: 'asc'
    };

    useEffect(async () => {
        let token =  await refreshAccessToken();
        if (token && token.accessToken) {
            window.localStorage.setItem('token', token.accessToken);
        }
        let param = { 
            ...sortCRD,
            pageNo: page,
            pageSize: 60,
            organizationID: config.organizationID
        };
        let configData = await GetConfigurationAPI(param);
        if(configData.responseCode === 200) {
            setConfigurationData(configData.configurations);
            setTotalResults(configData.totalResults);
        }
    }, []);

    const handleShow = async(selectedConfiguration, type) => {
        setError('');
        setShow(true);
        setMode(type);
        setShowPassword(true);
        if(type === 'Create') {
            let tempOrgDetails = {
                cloudName: '',
                connectionName: '',
                storageAccountKey: '',
                storageAccountName: '',
                blobContainer: '',
                azurePath: '',
                awsConnectionName: '',
                accessKeyId: '',
                secretAccessKey: '',
                bucket: '',
                region: '',
                awsPath: '',
            }
            setSelectedConfiguration(tempOrgDetails);
            setSelectedCloud({ cloudName: 'Microsoft Azure', connectionType: 1 });
        } else {
            if(selectedConfiguration.connectionType === 2) {
                setSelectedCloud({ cloudName: 'Amazon AWS', connectionType: 2 });
            } else {
                setSelectedCloud({ cloudName: 'Microsoft Azure', connectionType: 1 });
            }
            let param = { 
                cloudConfigurationID: selectedConfiguration.cloudConfigurationID,
                organizationID: config.organizationID
            };
            let configData = await GetPlainCloudConfigurationString(param);
            if(configData.responseCode === 200) {
                let data = JSON.parse(configData.data.connectionStringValue);
                setSelectedConfiguration({
                    ...selectedConfiguration,
                    connectionName: configData.data.connectionName,
                    storageAccountKey: data.AzureStorageAccountKey,
                    storageAccountName: data.AzureStorageAccountName,
                    blobContainer: data.BlobContainer,
                    azurePath: data.Path,
                    awsConnectionName: configData.data.connectionName,
                    accessKeyId: data.AWSAccessKeyId,
                    secretAccessKey: data.AWSSecretAccessKey,
                    bucket: data.Bucket,
                    region: data.Region,
                    awsPath: data.Path,
                });
            }
            const connectionName = selectedConfiguration.connectionType == '2' ? 'Amazon AWS' : 'Microsoft Azure';
            setSelectedCloud({ cloudName: connectionName, connectionType: selectedConfiguration.connectionType});
        }
    }

    const handleCloudNameChange = (event) => {
        let value = event.target.value;
        const connectionName = value == 'Amazon AWS' ? 'Amazon AWS' : 'Microsoft Azure';
        const connectionType = value == 'Amazon AWS' ? '2' : '1';
        setSelectedConfiguration({
            ...selectedConfiguration,
            cloudName: connectionName
        });
        setSelectedCloud({ cloudName: connectionName, connectionType:  connectionType})
    };

    const validateForm = () => {
        let tempError = true;
        if(selectedCloud.cloudName === 'Microsoft Azure') {
            if(selectedConfiguration.connectionName === '') {
                let tempErrorMsg = 'Please enter connection name';
                setError(tempErrorMsg);
                setIsFormError(true);
                tempError = false;
                return;
            } else {
                setIsFormError(false);
                tempError = true; 
            }
            if (selectedConfiguration.storageAccountKey === '') {
                let tempErrorMsg = 'Please enter storage account Key';
                setError(tempErrorMsg);
                setIsFormError(true);
                tempError = false;
                return;
            } else {
                setIsFormError(false);
                tempError = true; 
            }
            if (selectedConfiguration.storageAccountName === '') {
                let tempErrorMsg = 'Please enter storage account name';
                setError(tempErrorMsg);
                setIsFormError(true);
                tempError = false;
                return;
            } else {
                setIsFormError(false);
                tempError = true; 
            }
            if (selectedConfiguration.blobContainer === '') {
                let tempErrorMsg = 'Please enter blob container';
                setError(tempErrorMsg);
                setIsFormError(true);
                tempError = false;
                return;
            } else {
                setIsFormError(false);
                tempError = true; 
            }
            if (selectedConfiguration.azurePath === '') {
                let tempErrorMsg = 'Please enter path';
                setError(tempErrorMsg);
                setIsFormError(true);
                tempError = false;
                return;
            } else {
                setIsFormError(false);
                tempError = true; 
            }
            if(selectedConfiguration.connectionName) {
                let pattern = new RegExp(/^[A-Za-z0-9 ]+$/);
                if (!pattern.test(selectedConfiguration.connectionName)) {
                    setError('Please enter valid connection name');
                    setIsFormError(true);
                    tempError = false;
                    return;
                }
            }  
    
            if(tempError) {
                console.log(isFormError);
                setIsFormError(false);
            }
    
            return tempError;
        } else {
            if(selectedConfiguration.awsConnectionName === '') {
                let tempErrorMsg = 'Please enter connection name';
                setError(tempErrorMsg);
                setIsFormError(true);
                tempError = false;
                return;
            } else {
                setIsFormError(false);
                tempError = true; 
            }
            if (selectedConfiguration.accessKeyId === '') {
                let tempErrorMsg = 'Please enter access key id';
                setError(tempErrorMsg);
                setIsFormError(true);
                tempError = false;
                return;
            } else {
                setIsFormError(false);
                tempError = true; 
            }
            if (selectedConfiguration.secretAccessKey === '') {
                let tempErrorMsg = 'Please enter secret access key';
                setError(tempErrorMsg);
                setIsFormError(true);
                tempError = false;
                return;
            } else {
                setIsFormError(false);
                tempError = true; 
            }
            if (selectedConfiguration.region === '') {
                let tempErrorMsg = 'Please enter region';
                setError(tempErrorMsg);
                setIsFormError(true);
                tempError = false;
                return;
            } else {
                setIsFormError(false);
                tempError = true; 
            }
            if (selectedConfiguration.bucket === '') {
                let tempErrorMsg = 'Please enter bucket';
                setError(tempErrorMsg);
                setIsFormError(true);
                tempError = false;
                return;
            } else {
                setIsFormError(false);
                tempError = true; 
            }
            if (selectedConfiguration.awsPath === '') {
                let tempErrorMsg = 'Please enter path';
                setError(tempErrorMsg);
                setIsFormError(true);
                tempError = false;
                return;
            } else {
                setIsFormError(false);
                tempError = true; 
            }
            if(selectedConfiguration.awsConnectionName) {
                let pattern = new RegExp(/^[A-Za-z0-9 ]+$/);
                if (!pattern.test(selectedConfiguration.awsConnectionName)) {
                    setError('Please enter valid connection name');
                    setIsFormError(true);
                    tempError = false;
                    return;
                }
            } 
    
            if(tempError) {
                console.log(isFormError);
                setIsFormError(false);
            }
    
            return tempError;
        }
        
    }

    const CreateCloudConfiguration = async() => {
        let errRes = validateForm();
        if(errRes) {
            setError('');
            let param;
            if(selectedCloud.cloudName === 'Microsoft Azure') {
                let azureObj = {
                    AzureStorageAccountKey : selectedConfiguration.storageAccountKey,
                    AzureStorageAccountName :selectedConfiguration.storageAccountName,
                    BlobContainer : selectedConfiguration.blobContainer,
                    Path : selectedConfiguration.azurePath,
                }
                param = {
                    cloudConfigurationID: mode === 'Create' ? 0 : selectedConfiguration.cloudConfigurationID,
                    connectionType: selectedCloud.connectionType,
                    connectionName: selectedConfiguration.connectionName,
                    connectionStringValue: JSON.stringify(azureObj),
                    createdBy: config.userId,
                    organizationID: config.organizationID,
                };
            } else {
                let awsObj = {
                    AWSAccessKeyId : selectedConfiguration.accessKeyId,
                    AWSSecretAccessKey : selectedConfiguration.secretAccessKey,
                    Region : selectedConfiguration.region,
                    Bucket : selectedConfiguration.bucket,
                    Path : selectedConfiguration.awsPath,
                }
                param = {
                    cloudConfigurationID: mode === 'Create' ? 0 : selectedConfiguration.cloudConfigurationID,
                    connectionType: selectedCloud.connectionType,
                    connectionName: selectedConfiguration.awsConnectionName,
                    connectionStringValue: JSON.stringify(awsObj),
                    createdBy: config.userId,
                    organizationID: config.organizationID,
                };
            }
    
            let responseData = await CreateCloudConfigurationAPI(param);
            if(responseData.data.statusCode === 200) {
                if(responseData.data.status === 'ERROR') {
                    setError(responseData.data.message);
                } else {
                    setShow(false);
                    fetchMoreListItems(page);
                }
            } else {
                if(responseData.data.statusCode === 500) {
                    setError('Error - Kindly contact Support team for resolution');
                }
                else if(responseData.responseCode === 401) {
                    setError('Unauthorized');
                } else {
                    setError(responseData.data.title);
                }
            }
        }
    };

    const handleInputChange = (event, name) => {
        const value = event.target.value;
        setSelectedConfiguration({
            ...selectedConfiguration,
            [name]: value
        });
    };

    const deleteCloudConfiguration = async () => {
        setError('');
        let param = {
            organizationID: config.organizationID,
            cloudConfigurationID: selectedConfiguration.cloudConfigurationID,
            modifiedBy: config.userId
        };
        let responseData = await DeleteCloudConfiguration(param);
        if(responseData.data.statusCode == 200) {
            if(responseData.data.status === 'ERROR') {
                setError(responseData.data.message);
            } else {
                setShowDeleteModal(false);
                fetchMoreListItems(page);
            }
        } else {
            if(responseData.data.statusCode === 500) {
                setShowDeleteModal(true);
                setError('Error - Kindly contact Support team for resolution');
            }
            else if(responseData.responseCode === 401) {
                setShowDeleteModal(true);
                setError('Unauthorized');
            } else {
                setShowDeleteModal(true);
                setError(responseData.data.title);
            }
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const fetchMoreListItems = async(pageno) => {
        setScrollLoading(true);
        let pageNumber = pageno ? pageno : page;
        let sortCRD =  {
            sort: 'connectionName',
            order: 'asc',
            organizationID: config.organizationID,
            pageNo: pageNumber,
            pageSize: 60,
        };

    
        let configData = await GetConfigurationAPI(sortCRD);
        if(configData.responseCode === 200) {
            setTotalResults(configData.totalResults);
            setScrollLoading(false);
            if(configData.pageNo > 1) {
                if(configData.configurations.length > 0) {
                    let ids = new Set(configurationData.map(d => d.connectionName));
                    let mergedJobs = [...configurationData, ...configData.configurations.filter(d => !ids.has(d.connectionName))];
                    setConfigurationData(mergedJobs);
                    setScrollLoading(false);
                }
            } else {
                setConfigurationData(configData.configurations);
                setScrollLoading(false);
            }
        }
    }

    return (
        <>
            <Modal show={showDeleteModal} onHide={handleDeleteClose}  backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Cloud Connection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='tenant-container'>
                        <p className='modal-text-overlap'>Are you sure you want to delete {selectedConfiguration.connectionName} ?</p>
                    </div> 
                    {error ? <div className='error-message'>{error}</div> : ''}
                </Modal.Body>
                <Modal.Footer>
                    <ZuiButton emphasis='active' onClick={handleDeleteClose}>Cancel</ZuiButton>
                    <ZuiButton emphasis='active-primary' onClick={deleteCloudConfiguration}>Delete</ZuiButton>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}  backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{mode === 'Create' ? 'Add Cloud Connection' : 'Edit Cloud Connection'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedCloud.cloudName === 'Microsoft Azure' ? 
                        <div className='tenant-container'>
                            <div className='config-input-container'>
                                <label className='config-modal-label-text'>Cloud Name : <span style={{color: 'red'}}>*</span></label>
                                <div className="">
                                    <select disabled={mode !== 'Create' ? true: false} className='config-new-select-azure select-text' onChange={(event) => handleCloudNameChange(event, selectedConfiguration)} value={selectedCloud.cloudName} defaultValue={selectedCloud.cloudName}>
                                        <option value="Microsoft Azure">Microsoft Azure</option>
                                        <option value="Amazon AWS">Amazon AWS</option>
                                    </select>
                                    <span className="select-bar"></span>
                                </div>
                            </div>
                            
                            <div className='config-input-container'>
                                <label className='config-modal-label-text'>Connection Name: <span style={{color: 'red'}}>*</span></label>
                                <input type='text' onChange={(event) => handleInputChange(event, 'connectionName')} value={selectedConfiguration.connectionName}/>
                            </div>
                            <div className='config-input-container'>
                                <label className='config-modal-label-text'>Storage Account Key: <span style={{color: 'red'}}>*</span></label>
                                <input type={showPassword ? 'password' : 'text'} style={{marginLeft: 17}} onChange={(event) => handleInputChange(event, 'storageAccountKey')} value={selectedConfiguration.storageAccountKey}/>
                                <FontAwesomeIcon  onClick={handleClickShowPassword} className='eye-icon' icon={showPassword ? faEyeSlash : faEye} />
                            </div>
                            <div className='config-input-container'>
                                <label className='config-modal-label-text'>Storage Account Name: <span style={{color: 'red'}}>*</span></label>
                                <input type={showPassword ? 'password' : 'text'} onChange={(event) => handleInputChange(event, 'storageAccountName')} value={selectedConfiguration.storageAccountName}/>
                            </div>
                            <div className='config-input-container'>
                                <label className='config-modal-label-text'>Blob Container: <span style={{color: 'red'}}>*</span></label>
                                <input type={showPassword ? 'password' : 'text'} onChange={(event) => handleInputChange(event, 'blobContainer')} value={selectedConfiguration.blobContainer}/>
                            </div>
                            <div className='config-input-container'>
                                <label className='config-modal-label-text'>Path: <span style={{color: 'red'}}>*</span></label>
                                <input type={showPassword ? 'password' : 'text'} onChange={(event) => handleInputChange(event, 'azurePath')} value={selectedConfiguration.azurePath}/>
                            </div>
                            {error ? <div className='error-message'>{error}</div> : ''}
                        </div> :
                        <div className='tenant-container'>
                            <div className='config-input-container'>
                                <label className='config-modal-label-text'>Cloud Name : <span style={{color: 'red'}}>*</span></label>
                                <div className='wrap'>
                                    <select disabled={mode !== 'Create' ? true: false} className='config-new-select-azure select-text' onChange={(event) => handleCloudNameChange(event, selectedConfiguration)} value={selectedCloud.cloudName} defaultValue={selectedCloud.cloudName}>
                                        <option value="Microsoft Azure">Microsoft Azure</option>
                                        <option value="Amazon AWS">Amazon AWS</option>
                                    </select>
                                    <span className="select-bar"></span>
                                </div>
                            </div>
                            <div className='config-input-container'>
                                <label className='config-modal-label-text'>Connection Name: <span style={{color: 'red'}}>*</span></label>
                                <input type='text' onChange={(event) => handleInputChange(event, 'awsConnectionName')} value={selectedConfiguration.awsConnectionName}/>
                            </div>
                            <div className='config-input-container'>
                                <label className='config-modal-label-text'>Access Key Id: <span style={{color: 'red'}}>*</span></label>
                                <input type={showPassword ? 'password' : 'text'} style={{marginLeft: 17}}  onChange={(event) => handleInputChange(event, 'accessKeyId')} value={selectedConfiguration.accessKeyId}/>
                                <FontAwesomeIcon  onClick={handleClickShowPassword} className='eye-icon' icon={showPassword ? faEyeSlash : faEye} />
                            </div>
                            <div className='config-input-container'>
                                <label className='config-modal-label-text'>Secret Access Key: <span style={{color: 'red'}}>*</span></label>
                                <input type={showPassword ? 'password' : 'text'}  onChange={(event) => handleInputChange(event, 'secretAccessKey')} value={selectedConfiguration.secretAccessKey}/>
                            </div>
                            <div className='config-input-container'>
                                <label className='config-modal-label-text'>Region: <span style={{color: 'red'}}>*</span></label>
                                <input type={showPassword ? 'password' : 'text'}  onChange={(event) => handleInputChange(event, 'region')} value={selectedConfiguration.region}/>
                            </div>
                            <div className='config-input-container'>
                                <label className='config-modal-label-text'>Bucket: <span style={{color: 'red'}}>*</span></label>
                                <input type={showPassword ? 'password' : 'text'}  onChange={(event) => handleInputChange(event, 'bucket')} value={selectedConfiguration.bucket}/>
                            </div>
                            <div className='config-input-container'>
                                <label className='config-modal-label-text'>Path: <span style={{color: 'red'}}>*</span></label>
                                <input type={showPassword ? 'password' : 'text'}  onChange={(event) => handleInputChange(event, 'awsPath')} value={selectedConfiguration.awsPath}/>
                            </div>
                            {error ? <div className='error-message'>{error}</div> : ''}
                        </div> 
                    }
                    
                </Modal.Body>
                <Modal.Footer>
                    <ZuiButton emphasis='active' onClick={handleClose}>Cancel</ZuiButton>
                    <ZuiButton emphasis='active-primary' onClick={CreateCloudConfiguration}>Save</ZuiButton>
                </Modal.Footer>
            </Modal>
            <div className='tenants-main-container'>
                <div className='tenant-list-header'>
                    <div>
                        <p className='header-text'>Cloud Connection Settings</p>
                    </div>
                    <div className='icon-container'>
                        <div className='button-container' data-tip='Add Cloud Connection' data-for="registerTip">
                            <ZuiIconPlus size='l' onClick={() => handleShow(selectedConfiguration, 'Create')} />
                            <ReactToolTip 
                                place='bottom'
                                effect='solid'
                                id="registerTip">
                            </ReactToolTip>
                        </div>
                    </div>
                </div>
                <ConfigurationList scrollLoading={scrollLoading} setScrollLoading={setScrollLoading} totalResults={totalResults} page={page} setPage={setPage} fetchMoreListItems={fetchMoreListItems} configurationData={configurationData} onClick={handleShow} handleDeleteModalShow={handleDeleteModalShow}/>
            </div>
        </>
    )
}